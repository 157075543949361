<script>

import _ from 'lodash';
import SelectFunctions from '../select/SelectFunctions';
import ErrorMessages from '../components/ErrorMessages';
export default
  {

    components: { ErrorMessages },


    props: ['editItem', 'showDialog'],
    mounted ()
    {
      this.superUserCheck();
      this.getUsergroup();
    },

    methods:
    {
      async userDefaultPurchaseOrderCheck ()
      {

        if (!this.editItemData._id)
        {
          this.editItemData.defaultPurchaseOrder = 'ALL';
        }

      },
      async superUserCheck ()
      {
        let response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + 'users/superusercheck',
          { credentials: 'include' }
        );
        if (response.ok)
        {
          this.isSuperUser = await response.json();
        }
        else
        {
          this.errorList.push('There was a problem retrieving the users list from the server.')
        }

      },
      async getUsergroup ()
      {
        let response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + 'usergroups/',
          { credentials: 'include' }
        );
        if (response.ok)
        {
          this.usergroupList = await response.json();
        }
        else
        {
          this.errorList.push('There was a problem retrieving the usergroups list from the server.')
        }

      },
      usergroupRemove ()
      {
        this.editItemData.usergroup = null;
      },
      async saveForm ()
      {
        if (this.isSuperUser)
        {

          if (this.$refs.formUser.validate())
          {

            this.$refs.formUser.resetValidation();
            if (!this.editItemData._id)
            {

              // are in add mode
              let response = await SelectFunctions.fetch(
                process.env.VUE_APP_BACKEND_ENDPOINT + 'users/new',
                {
                  credentials: 'include',
                  method: 'PUT',
                  headers:
                  {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(this.editItemData)
                }
              );
              if (response.ok)
              {
                let duplicateEntry = await response.json();

                if (duplicateEntry.text === "duplicate")
                {
                  this.errorList.push(
                    duplicateEntry.error
                  );
                }
                else
                {
                  this.$emit('newRecordInserted');
                  this.errorList = [];
                  this.dialog = false;
                }
              }
              else
              {
                this.errorList.push(
                  'There was a problem saving the new record, please re-try'
                );
              }

            }
            else
            {
              // we are in edit mode
              let response = await SelectFunctions.fetch(
                process.env.VUE_APP_BACKEND_ENDPOINT + 'users/',
                {
                  credentials: 'include',
                  method: 'PATCH',
                  headers:
                  {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(this.editItemData)
                }

              );
              if (response.ok)
              {
                let duplicateEntry = await response.json();

                if (duplicateEntry.text === "duplicate")
                {
                  this.errorList.push(
                    duplicateEntry.error
                  );
                }
                else
                {
                  this.$emit('newRecordInserted');
                  this.errorList = [];
                  this.dialog = false;
                }
              }
              else
              {
                this.errorList.push(
                  'There was a problem saving the new record, please re-try'
                );
              }
            }

          }   // end if the user form is valid 

        }
        else
        {
          this.errorList.push(
            'You do not have permission to add/edit a user.'
          );
        }
      },
      cancelForm ()
      {
        this.dialog = false;
        this.$emit('newRecordCancelled');
        // this.errors.clear();
      },

    },

    watch:
    {
      showDialog (newValue, oldValue)
      {
        if (this.showDialog)
        {
          this.editItemData = this.editItem;
          this.errorList = [];
          this.userDefaultPurchaseOrderCheck();
          this.dialog = true;
        }
      }
    },

    data ()
    {
      return {
        dialog: false,
        passwordVisible: false,
        editItemData: {},
        errorList: [],
        usergroupList: [],
        isSuperUser: false,
        userDefaultPurchaseOrder: [
          { name: 'ALL' },
          { name: 'Standard Purchase Orders' },
          { name: 'Maintenance Job PO' }
        ],
      }
    },

  }
</script>

<template>
  <v-dialog-transition>
    <div>
      <v-dialog
        v-model="dialog"
        width="1024"
        persistent
      >
        <v-card>
          <v-card-title
            class="headline primary xlighten-2 white--text"
            primary-title
            v-if="this.editItemData._id"
          >
            Edit User
          </v-card-title>
          <v-card-title
            v-else
            class="headline primary xlighten-2 white--text"
            primary-title
          >
            New User
          </v-card-title>
          <v-card-text>

            <v-layout
              row
              wrap
            >
              <error-messages :errorList="errorList"></error-messages>
            </v-layout>
            <v-form ref="formUser">
              <v-container
                fluid
                class='mt-10'
              >
                <v-flex
                  ms12
                  md12
                >
                  <v-flex
                    sm12
                    md12
                  >
                    <v-text-field
                      label='Username'
                      placeholder='Username'
                      v-model="editItemData.username"
                      :rules="[v=>!!v || 'Please enter a username']"
                      autocomplete="new-password"
                    />
                    <v-text-field
                      label='Fullname'
                      placeholder='Fullname'
                      v-model="editItemData.fullname"
                      :rules="[v=>!!v || 'Please enter the user\'s full name']"
                    />
                  </v-flex>
                </v-flex>

                <v-flex
                  ms12
                  md12
                >
                  <v-flex
                    sm12
                    md12
                  >
                    <v-text-field
                      label="Password"
                      :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="passwordVisible ? 'text' : 'password'"
                      v-model="editItemData.password"
                      @click:append="passwordVisible = !passwordVisible"
                      required
                      autocomplete='new-password'
                      :rules="[
                        v=> (!!v || (editItemData._id ? true : false)) || 'Please enter a password'
                ]"
                    ></v-text-field>
                    <v-text-field
                      label="Confirm Password"
                      :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="passwordVisible ? 'text' : 'password'"
                      v-model="editItemData.confirmPassword"
                      @click:append="passwordVisible = !passwordVisible"
                      autocomplete='new-password'
                      :rules="[
                      v=> (editItemData.password===v) || 'Passwords entered do not match'
                    ]"
                    ></v-text-field>
                  </v-flex>

                  <v-flex
                    sm12
                    md12
                  >
                    <v-text-field
                      label='Email Address'
                      placeholder='Email Address'
                      v-model="editItemData.emailAddress"
                    />
                  </v-flex>

                  <v-autocomplete
                    :items="usergroupList"
                    label='Usergroup'
                    placeholder='Usergroup'
                    item-value='_id'
                    item-text='name'
                    chips
                    deletable-chips
                    small-chips
                    v-model="editItemData.usergroup"
                    :rules="[v=> !!v || 'Please enter a user group']"
                  >
                    <template
                      slot="selection"
                      slot-scope="data"
                    >
                      <v-chip
                        color="accent"
                        class='white--text'
                        close
                        @input="usergroupRemove(data.item)"
                      >
                        {{data.item.name}}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-flex>

              </v-container>
            </v-form>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              @click.native='saveForm()'
            >Save</v-btn>
            <v-btn @click.native="cancelForm()">Cancel</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </div>

  </v-dialog-transition>
</template>