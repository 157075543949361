<script>
export default {
  props: ['errorList']
};

</script>

<template>
  <v-layout
    row-wrap
    v-if="errorList && errorList.length > 0 "
    pa-0
  >
    <v-flex xs12>
      <v-alert
        :value="errorList && errorList.length > 0 "
        color="error"
        icon="mdi-alert"
        dark
      >
        <span
          v-for="(error) in errorList"
          :key="error"
        >
          {{error}}<br />
        </span>
      </v-alert>
    </v-flex>
  </v-layout>
</template>
