<script>

import SelectFunctions from '../select/SelectFunctions';
import ErrorMessages from '../components/ErrorMessages';
import UserAddEdit from '../views/User_AddEdit';

import _ from 'lodash';

export default
  {
    components: { ErrorMessages,  UserAddEdit },

    created()  
    {


    },

    mounted()
    {
      this.superUserCheck();
      this.getUsergroupList();

    },

    methods:
    {
      async superUserCheck()
      {
        let response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + 'users/superusercheck',
          { credentials: 'include' }
        );
        if (response.ok)
        {
          this.isSuperUser = await response.json();
        }
        else
        {
          this.errorList.push('There was a problem retrieving the users list from the server.')
        }

      },
      async getUsergroupList()
      {
        this.processing = true;

        let response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + 'users/usergroups',
          { credentials: 'include' }
        );
        if (response.ok)
        {
          this.userGroupList = await response.json();
          this.getUserList();
        }
        else
        {
          this.errorList.push('There was a problem retrieving the users list from the server.')
        }
        this.processing = false;

      },
      async getUserList()
      {
        this.processing = true;

        let response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + 'users/',
          { credentials: 'include' }
        );
        if (response.ok)
        {

          this.userListInternal = await response.json();

          let userCounter = 0;
          let userGroupCounter = 0;
          this.userListInternal.forEach(() =>          {

            this.userGroupList.forEach(() =>            {

              if (this.userListInternal[ userCounter ].usergroup === this.userGroupList[ userGroupCounter ]._id)
              {
                this.userListInternal[ userCounter ].usergroup_name = this.userGroupList[ userGroupCounter ].name;
              }

              userGroupCounter++;
            });

            userCounter++;
            userGroupCounter = 0;
          });
        }
        else
        {
          this.errorList.push('There was a problem retrieving the users list from the server.')
        }
        this.processing = false;

      },

      newItem()
      {
        this.itemToEdit = {},
          this.showEditDialog = true;
        _.defer(() =>
        {
          this.showEditDialog = false;
        });

      },

      editItem(item)
      {
        this.itemToEdit = _.clone(item);
        this.showEditDialog = true;
        _.defer(() =>
        {
          this.showEditDialog = false;
        });

      },

      async deleteItem(item)
      {
        let res = await this.$root.$confirm(
          'Are you sure that you wish to delete this user record?',
          {
            title: 'Delete User',
            color: 'red'
          }
        );

        if (res)
        {
          if (this.isSuperUser)          {
            let response = await SelectFunctions.fetch(
              process.env.VUE_APP_BACKEND_ENDPOINT + 'users/',
              {
                method: 'DELETE',
                credentials: 'include',
                headers:
                {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.item)
              }
            );
            this.getUsergroupList();
          }
          else          {
            this.errorList.push('You do not have permission to delete a user.');
          }
        }

      },
      handleRecordInsert()
      {
        this.getUsergroupList();
      },
      handleRecordCancel()
      {
        this.getUsergroupList();
      },

    },

    computed:
    {
      UsergroupList()
      {
        return this.userListInternal;
      }
    },
    data: () =>
    {
      return {
        breadcrumbs: [
          {
            text: 'Home',
            icon: 'mdi-home',
            link: '/'
          },
          {
            text: 'User Management',
            icon: '',
            link: '#',
          }
        ],
        userListInternal: [],
        itemToEdit: {},
        itemToDelete: {},
        showEditDialog: false,
        showConfirmDelete: false,
        deleteUserGroupConfirm: true,
        userGroupList: [],
        isSuperUser: false,
        errorList: [],
        processing: false,
        selected: [],
        search: '',
        pagination: {
          sortBy: '',
          descending: true
        },
        headers: [
          {
            text: 'Name',
            value: 'username'
          },
          {
            text: 'Usergroup',
            value: 'usergroup_name'
          },
          {
            text: 'Email Address',
            value: 'emailAddress'
          },
          {
            text: 'Edit',
            value:'edit',
            sortable: false
          },
          {
            text: 'Delete',
            value:'delete',
            sortable: false
          }
        ],
      }
    }
  }

</script>

<template>
  <v-slide-y-transition mode="out-in">
    <div>
      <v-container fluid>
        <v-card>
          <v-card-text>
            <v-layout row wrap>
              <v-flex md6>
                <v-breadcrumbs divider=">">
                  <v-breadcrumbs-item
                    v-for="item in breadcrumbs"
                    :key="item.text"
                    :to="item.link"
                    exact
                  >
                    <v-icon v-if="item.icon">{{item.icon}}&nbsp;</v-icon>
                    {{item.text}}
                  </v-breadcrumbs-item>
                </v-breadcrumbs>
              </v-flex>
              <v-flex md6>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  placeholder="Search"
                  prepend-icon="mdi-magnify"
                  clearable
                />
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>

        <v-card style="margin-top:20px;">
          <v-card-text>
            <v-layout  wrap>
              <v-col cols="12" xs="12">
                <v-btn color="accent" dark fab xtop middle right @click="newItem()" absolute>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>

                <span class="headline">Users Management</span>
                <p>
                  This form allows you to add / edit and remove users in the system.
                  <br />
                </p>
                <v-spacer></v-spacer>
              </v-col>
            </v-layout>
          </v-card-text>
        </v-card>

        <v-card style="margin-top:20px;">
          <v-card-text>
            <error-messages :errorList="errorList"></error-messages>

            <v-layout row v-show="processing">
              <v-flex sm12>
                <v-progress-linear v-if="processing" :indeterminate="true"></v-progress-linear>
              </v-flex>
            </v-layout>

            <!-- DATA TABLE FOR USERS LIST -->
            <v-data-table
              :headers="headers"
              :items="userListInternal"
              class="elevation-0"
              :loading="'Loading'"
              :search="search"
              :pagination.sync="pagination"
              v-model="selected"
              :rows-per-page-items="[20,50,100]"
            >
              
              <template v-slot:item.edit="{item}">
                
                  <v-btn color="primary" class="mx-0" @click="editItem(item)">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                
              </template>
              <template v-slot:item.delete="{item}">

                <td>
                  <v-btn color="red" dark class="mx-0" @click="deleteItem(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </template>
              <template slot="no-data">
                <v-alert :value="true" color="info" icon="warning">No Users Located</v-alert>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-container>
      <user-add-edit
        :editItem="itemToEdit"
        :showDialog="showEditDialog"
        @newRecordInserted="handleRecordInsert()"
        @newRecordCancelled="handleRecordCancel()"
      ></user-add-edit>
      
    </div>
  </v-slide-y-transition>
</template>